

const Admin = () => {

  
  return (

    <div>Hi Admin</div>
  )

}

export default Admin